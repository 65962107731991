import { FC, useEffect, useRef } from 'react'
import { DEVICE_TYPE, VIEW_TYPE } from '../../utils/constant'
import {HomeHeader} from '.'
import Text from '../Text'
import { IoPaperPlaneOutline, IoLogoWhatsapp } from "react-icons/io5";
import { useChat, getDisplayName, useVisitor, useApp, Conversation, tryParseJSON, useLang} from 'alpha-chat';
import cn from 'clsx'
import './home.css';
import moment from 'moment';
import getChannelType from '../../utils/channelTypes';
import Container from './Container';
import formatValue from '../../utils/formatValue';
import LoadingSpinner from '../LoadingSpinner';

const HomeView: FC<{
  deviceType?: DEVICE_TYPE
  onToggle?: () => void
  onToggleCurrentView?: (value: VIEW_TYPE, conversationKey?: any) => void
}> = ({deviceType, onToggle, onToggleCurrentView}) => {

   const { app_settings } = useApp()
   const { lang } = useLang()

   const {
      isConnected,
      conversation,
      newConversation,
      conversations,
      setIsFetching,
      clearLastConversation,
      setView
   } = useChat()

   const { visitor_data } = useVisitor()

   useEffect(() => {
      setView('HOME')
   }, [])
   
   
   const boxShadowCard = "0px 6px 11px 0px rgb(159 159 159 / 45%)"

   const handleStartConversation = () => {
      if(!isConnected) return

      if(Object.keys(visitor_data).length == 0 && app_settings?.login_form){
         onToggleCurrentView && onToggleCurrentView('LOGIN')
      } else if(Object.keys(visitor_data).length == 0 && app_settings?.contact_form){
         onToggleCurrentView && onToggleCurrentView('REGISTER')
      } else {
         onToggleCurrentView && onToggleCurrentView('CHAT')

         window.top?.postMessage(
            {
               updateSession: {
                  key: 'has_conversation',
                  value: true,
                  isUpdateIfExist: false,
               }
            },
            '*'
          )
         setIsFetching(true)
      }
   }

   const displayNameAgent = (name: any, defaultName: string = '') => {
      if(app_settings?.['chat-widget']?.default_agent_name) return app_settings?.['chat-widget']?.default_agent_name
      
      return getDisplayName(name, defaultName)
    }
   
   const disableLiveChat = app_settings?.['chat-widget'].enbale_live_chat !== undefined ?
                              !app_settings?.['chat-widget'].enbale_live_chat : false

   return (
      <Container
         deviceType={deviceType}
         onToggle={onToggle}
         onToggleCurrentView={onToggleCurrentView}>
         {
            !disableLiveChat &&
            conversations.length !== 0 &&
            <div 
               className="py-4 m-4 flex flex-col bg-white" 
               style={{
                  boxShadow: boxShadowCard,
                  borderRadius: `${app_settings?.['chat-widget']?.ui?.corner_radius || 6}px`
               }}
            >
               <Text size="lg" className="font-semibold px-4 mb-2">{lang.continue_the_conversation}</Text>
               {
                  conversations.map((data) => {
                     const { last_message, channel } = data
                     const isSelf = !Boolean(last_message?.sender.agent)
                     const sentAt = last_message?.sent_at
                     const body = data?.last_message?.body
                     const content = typeof body === 'string' ? tryParseJSON(body) : body
                     const text = content ? content.text : body

                     return (
                        <div 
                           key={data.uid} 
                           onClick={() => onToggleCurrentView && onToggleCurrentView('CHAT', data.uid)}
                           className="px-4 py-2 hover:bg-[#47556917] cursor-pointer"
                        >
                           <div className={cn("flex justify-between", {'text-gray-400': data.closed_at})}>
                              <Text size='none' className={cn("truncate text-sm font-semibold")} classNameString="truncate text-sm font-semibold">
                                 {isSelf ? 'You' : displayNameAgent(last_message.sender.agent, 'Agent')}
                              </Text>
                              <div className="inline-flex">
                                 <Text size='none' className="text-sm truncate" classNameString="text-sm truncate max-w-[110px]">{sentAt ? formatValue(sentAt, "datetime|fromnow") : '-'}</Text>
                                 {data?.new_count !== 0 && <Text size='none' className="text-sm bg-red-600 px-[0.4rem] text-white rounded-full ml-2 flex items-center">{data?.new_count}</Text>}
                              </div>
                           </div>
                           <Text size="none" className={cn("text-sm truncate body-text-preview", {'text-gray-400': data.closed_at})}>
                              {text || '-'}
                           </Text>
                        </div>
                     )
                  })
               }
            </div>
         }
         <div 
            className="p-4 m-4 flex flex-col gap-y-8 bg-white" 
            style={{
               boxShadow: boxShadowCard,
               borderRadius: `${app_settings?.['chat-widget']?.ui?.corner_radius || 6}px`
            }}
         >
            {
               !disableLiveChat &&
               <div>
                  <Text size="lg" className="font-semibold mb-4">{lang.start_conversation}</Text>
                  {
                     isConnected ? (
                        <div 
                           onClick={() => handleStartConversation()}
                           className={cn(
                              "p-2 border-sold cursor-pointer border border-gray-500 hover:border-blue-400 flex justify-between items-center"
                           )}
                           style={{
                              borderRadius: `${app_settings?.['chat-widget']?.ui?.corner_radius || 8}px`
                           }}
                        >
                           <Text className="text-gray-400">{lang.hi_i_would_like}</Text>
                        </div>
                     ) : (
                        <div
                           className={cn(
                              "p-2 flex items-center border border-white"
                           )}
                           style={{
                              borderRadius: `${app_settings?.['chat-widget']?.ui?.corner_radius || 8}px`
                           }}
                        >
                           <LoadingSpinner className="!w-5 !h-5 mr-3" />
                           <Text className="text-gray-400">{lang.please_wait}</Text>
                        </div>
                     )
                  }
               </div>
            }
            {
               app_settings?.channels &&
               <div className="grid gap-y-2">
                  <Text size="lg" className="font-semibold mb-2">{lang.talk_to_us_on_your_favorite_channels}</Text>
                  {
                     app_settings.channels.map((data, i) => (
                        <a key={i} href={data.url} target="_blank" rel="noreferrer" className="">
                           <div 
                              className="p-2 gap-x-3 cursor-pointer border-sold border border-gray-500 hover:border-blue-400 flex items-center"
                              style={{
                                 borderRadius: `${app_settings?.['chat-widget']?.ui?.corner_radius || 8}px`
                              }}
                           >
                              <img 
                                 src={getChannelType(data.account_type).image}
                                 style={{ maxWidth: 28, width: 28, marginRight: 8 }} /> 
                              <Text>{data.name}</Text>
                           </div>
                        </a>
                     ))
                  }
               </div>
            }
         </div>
      </Container>
   )
}

export default HomeView